import { joinURL } from 'ufo'

interface Modifiers {
  width?: number
  height?: number
  format?: string
  fit?: string
  [key: string]: any
}

interface ImageCTX {
  options: any
  $img: any
}

export function getImage(
  src: string,
  { modifiers, baseUrl }: { modifiers: Modifiers; baseUrl: string },
  ctx: ImageCTX
) {
  const { width, height, format, fit, ...providerModifiers } = modifiers
  const operations: string[] = []

  const operationsString = operations.join(',')
  return {
    url: joinURL(baseUrl, operationsString, src),
  }
}
