
import * as s3Runtime$AugNzYcg40 from '/app/utils/s3.ts'
import * as ipxRuntime$CLzdGuhkTt from '/app/node_modules/.pnpm/@nuxt+image@1.0.0-rc.2_rollup@3.29.1/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['s3']: { provider: s3Runtime$AugNzYcg40, defaults: {"baseUrl":"https://doechk56wu3z8.cloudfront.net/img"} },
  ['ipx']: { provider: ipxRuntime$CLzdGuhkTt, defaults: undefined }
}
        